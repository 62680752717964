import Countdown, { zeroPad } from 'react-countdown';
import { useTranslation } from 'react-i18next';
import HomePageHeader from './HomePageHeader';

interface CountdownProps {
    total: number;
    minutes: number;
    seconds: number;
    days: number;
    hours: number;
    completed: boolean;
    chidlren: any;
    translations: any
}

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed, days, translations }: CountdownProps) => {
    if (completed) {
        return <div>Done</div>;
    }

    const renderTimeUnitsWithBackground = (timeUnit: string, text: string) => {
        return (
            <div className='flex flex-col items-end justify-end'>
                <div className="flex md:gap-[15px] gap-[5px] items-center justify-end text-white md:h-[120px] h-[90px]">
                    {timeUnit.split('').map((char, index) => (
                        <span
                            key={index}
                            className="bg-[#F08B1F] rounded-[5px] text-white px-[5px] md:w-[75px] w-[30px] text-center font-[700] h-[40px] md:h-full align-middle"
                        >
                            {char}
                        </span>
                    ))}
                </div>
                <p className="text-[14px] md:text-[16px] text-center md:h-full w-full md:mt-4">{text}</p>
            </div>
        );
    };
    // Render a countdown
    return (
        <div className=" bg-cover md:h-[380px] h-[500px] background-banner-countdown my-block flex items-center justify-center rounded-[10px]">
            <section className="rounded-[10px] md:p-[40px] p-[25px] gap-[10px] mx-[20px] relative text-white flex flex-col items-center">
                <p className="p-4 text-center text-[22px] font-[700] md:h-full h-[100px]">{translations.bannerText}</p>
                <article className="flex md:gap-[10px] text-white md:text-[60px] text-[28px] justify-center">
                    {renderTimeUnitsWithBackground(zeroPad(hours + days * 24), translations.hours)} <p className='md:mt-2 mt-[20px] w-[15px] text-center max-md:ml-2'>:</p>
                    {renderTimeUnitsWithBackground(zeroPad(minutes), translations.minutes)} <p className='md:mt-2 mt-[20px] w-[15px] text-center max-md:ml-2 '>:</p>
                    {renderTimeUnitsWithBackground(zeroPad(seconds), translations.seconds)}
                </article>
            </section>
        </div>
    );
};

export default function CountDown({src, title, subtitle} : {src?: string, title?: string; subtitle?: string}) {
    const { t } = useTranslation();

    const translations = {
        hours: t('newYear.hours'),
        minutes: t('newYear.minutes'),
        seconds: t('newYear.seconds'),
        bannerText: t('newYear.bannerText'),
    };
    return (
        <Countdown
            date={new Date('2025-01-01T00:00:00.000+01:00')}
            renderer={(props: any) => !props.completed ? renderer({...props, translations}) : <HomePageHeader src={src} title={title} subtitle={subtitle}/>}
        />
    );
}
