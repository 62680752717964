import generateAdPayload, { AdResponse, generateAdnAdPayload } from 'features/advertising/generateAdPayload';
import { getAds } from 'lib/ApiService';
import { getQueryParams } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Tags } from 'types';

type UseAdsProps = {
    page: string;
    audio?: boolean;
    articleId?: string;
    tags?: Tags[] | undefined;
    isLoading?: boolean;
    reloadOnLocationChange?: boolean;
    reloadOnChangeString?: string;
    customLocation?: string;
};

export default function useAds({
    page,
    audio,
    articleId,
    tags,
    isLoading = false,
    reloadOnLocationChange = true,
    reloadOnChangeString,
    customLocation
}: UseAdsProps) {
    const [ads, setAds] = useState<AdResponse | { data: [] }>();

    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter((segment) => segment !== '');

    const dep = reloadOnLocationChange ? [tags, location] : [tags];

    useEffect(() => {
        if (!isLoading) {
            generateAdPayload(
                page,
                pathSegments.length,
                customLocation || location.pathname,
                tags?.map((tag) => tag.tag),
                articleId,
                audio ? 'audio' : 'video',
            ).then((payload) => {
                setAds(undefined);
                return getAds(payload)
                    .then(setAds)
                    .catch(() => setAds({ data: [] }));
            });
        }
    }, [location.pathname, isLoading, reloadOnChangeString]);

    return {
        ads,
        setAds,
    };
}
