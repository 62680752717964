import { IonContent, generateId, IonPage } from '@ionic/react';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { PlayerContext, type PlayerContextType } from 'features/radio/PlayerContext';
import { useLocation } from 'react-router';
import useResponsive from 'features/responsive/responsive';
import { shouldSendArticleId } from 'AppRoutes';
import useAds from 'hooks/useAds';
import ShouldDisplayHoverboardAd from 'hooks/shouldDisplayHoverboardAd';
import { Tags } from 'types';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import DesktopFooter from './DesktopFooter';
import AdComponent from './Ads/AdComponent';

export type PageProps = {
    title?: string;
    hasLogo?: boolean;
    hasBillboard?: boolean;
    hasBackButton?: boolean;
    backPath?: string;
    cssClass?: string;
    noPadding?: boolean;
    hideLightHalo?: boolean;
    reduceBottomSpace?: boolean;
    tags?: Tags[];
    doubleBack?: boolean;
};

const STYLE_NO_PADDING = {
    '--padding-start': '0px',
    '--padding-end': '0px',
};

export const ScrolledContext = createContext(false);

function HoverBoard({ tags }: { tags: Tags[] }) {
    const location = useLocation();

    const { ads } = useAds({
        page: 'hoverboard',
        articleId: shouldSendArticleId() ? location.pathname.split('/').pop() : '0',
        tags,
    });

    // eslint-disable-next-line no-nested-ternary
    return ads ? (
        <div>
            <AdComponent
                ad={ads.data[0]}
                hasSpacings
                className="!mt-0"
            />
        </div>
    ) : null;
}

function PageLayout({
    title,
    children,
    cssClass = '',
    hasBillboard,
    hasBackButton,
    backPath,
    hasLogo,
    noPadding,
    hideLightHalo,
    reduceBottomSpace,
    tags,
    doubleBack
}: PropsWithChildren<PageProps>) {
    const [isScrolled, setIsScrolled] = useState(false);
    const { IsDesktop } = useResponsive();
    const [eldoHeaderAnchor] = useState(`anchor-${String(generateId('eldo--header-anchor'))}`);
    const { playerState, playerHeight } = useContext<PlayerContextType | null>(PlayerContext) as PlayerContextType;

    const { shouldDisplayHoverboardAd: isRouteLegaMention } = ShouldDisplayHoverboardAd();

    useEffect(() => {
        const onIntersection: IntersectionObserverCallback = (entries, observer) => {
            setIsScrolled(!entries[0].isIntersecting);
        };
        const observer = new IntersectionObserver(onIntersection, { threshold: 1, rootMargin: '0px' });
        observer.observe(document.querySelector(`#${eldoHeaderAnchor}`) as Element);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <ScrolledContext.Provider value={isScrolled}>
            <IonPage className={`page-component ${cssClass ?? ''}`}>
                <MobileHeader
                    title={title}
                    hasBillboard={hasBillboard}
                    hasBackButton={hasBackButton}
                    hasLogo={hasLogo}
                    reduceBottomSpace={reduceBottomSpace}
                    backPath={backPath}
                    doubleBack={doubleBack}
                />
                <DesktopHeader
                    isScrolled={isScrolled}
                    reduceBottomSpace={reduceBottomSpace}
                />

                <IonContent
                    id="eldo-content"
                    className={`page-component__content ${cssClass ?? ''}__content`}
                    style={{
                        '--padding-bottom': playerState.isVisible ? `${playerHeight}px` : '',
                        ...(noPadding ? STYLE_NO_PADDING : {}),
                    }}
                >
                    <div
                        id={eldoHeaderAnchor}
                        className="p-0"
                    />
                    {isRouteLegaMention ? null : <HoverBoard tags={tags ?? []} />}
                    {!hideLightHalo ? <div className="eldo-bg-image" /> : ''}
                    {children}
                    <DesktopFooter />
                </IonContent>
            </IonPage>
        </ScrolledContext.Provider>
    );
}

PageLayout.defaultProps = {
    cssClass: '',
    hasBillboard: false,
    hasBackButton: false,
    hasLogo: false,
    title: '',
};

export default PageLayout;
