import { Preferences } from '@capacitor/preferences';
import moment from 'moment';
import { TOKEN_IDENTIFIER } from 'features/auth/AuthContext';
import { User } from 'features/auth/types';
import { Charts } from 'features/charts/types';
import type { Event, EventResponse } from 'features/event/types';

import { WebChannel, Radio } from 'features/radio/types';
import {
    Article,
    TopicCategory,
    TrafficInfo,
    TeamPageInfo,
    HomePageData,
    ShowsPageInfo,
    NewsCategory,
    GamePageInfo,
    TicketingPageInfo,
    NewsResponse,
    Show,
    PictureReportResponse,
    PodcastAndReplayPageData,
    PodcastOrReplay,
    GamingTags,
    CategorizedReplays,
    CinemasResponse,
    MoviesByCinemaResponse,
    MovieResponse,
    PictureDetailResponse,
    CinemaArticles,
    WeekDates,
    ActionList,
    PicturesReport,
    Picture,
    LetzAiApprovedPicture,
} from 'types';
import generateAdPayload, { AdPayload, AdResponse, getDeviceType } from 'features/advertising/generateAdPayload';
import { isPlatform } from '@ionic/react';
import { Weather } from '../features/meteo/types';
import { isValidJwt, isTokenExpired, decodeJWT } from './JWTService';

export const BASE_URL = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/api` : '';
// export const BASE_URL = 'http://localhost:8080/api';
export const QUERY_KEY_RADIO = 'radio';

export const CINEMAS_URL = `${BASE_URL}/cinemas`;

export const EVENTS_URL = `${BASE_URL}/events`;
export const EVENTS_CATEGORIES_URL = `${BASE_URL}/events/categories`;

export const TOPIC_CATEGORIES_URL = `${BASE_URL}/topics/categories`;
export const TOPICS_URL = (categoryId: string) => `${BASE_URL}/articles/category/${categoryId}?limit=8`;
export const TOPIC_ARTICLE_URL = (articleId: string) => `${BASE_URL}/articles/${articleId}`;

export const NEWS_BY_CATEGORY_URL = `${BASE_URL}/articles/category`;
export const NEWS_CATEGORIES_URL = `${BASE_URL}/articles/category`;
export const GAMING_TAGS_URL = `${BASE_URL}/gaming/categories`;

export const WEBCHANNELS_URL = `${BASE_URL}/webchannels/`;
export const CHARTS_URL = `${BASE_URL}/charts`;
export const HOMEPAGE_URL = `${BASE_URL}/homepage`;
export const PODCAST_OR_REPLAY_URL = `${BASE_URL}/podcasts-replays`;
export const PODCASTS_URL = `${BASE_URL}/podcasts`;
export const PODCAST_REPLAY_RECENTLY_VIEWED = `${BASE_URL}/podcast-replays/recently-viewed`;
export const REPLAYS_URL = `${BASE_URL}/replays`;
export const NEWS_URL = `${BASE_URL}/articles`;

export const ARTICLE_TAG_URL = `${BASE_URL}/articles/tags`;
export const SHOWS_URL = `${BASE_URL}/shows`;
export const RADIO_URL = `${BASE_URL}/radio`;
export const TRAFFIC_INFO_URL = `${BASE_URL}/traffic`;
export const WEATHER_URL = `${BASE_URL}/weather`;
export const TEAMPAGE_INFO_URL = `${BASE_URL}/teams`;
export const GAMEPAGE_INFO_URL = `${BASE_URL}/games`;
export const TICKETINGPAGE_INFO_URL = `${BASE_URL}/tickets/contexts/eldo_ticket`;
export const PARTICIPATE_IN_GAME_URL = (gameId: number) => `${BASE_URL}/games/${gameId}/participate`;
export const TICKETINGPAGE_SEND_TICKET_URL = `${BASE_URL}/tickets/contexts/eldo_ticket/entries`;
export const LETZAI_URL = `${BASE_URL}/letzai`;
export const LETZAI_GENERATE_IMAGE = `${LETZAI_URL}/generate-image`;
export const REGISTER_URL = `${BASE_URL}/register`;
export const VALIDATE_REGISTER_URL = `${BASE_URL}/register/validate`;
export const SEND_VALIDATION_URL = `${BASE_URL}/register/send-validation-code`;
export const LOGIN_URL = `${BASE_URL}/login_check`;
export const ACCOUNT_URL = `${BASE_URL}/account`;
export const RESET_PASSWORD_URL = `${BASE_URL}/reset-password`;
export const RESET_PASSWORD_CONFIRMATION_URL = `${BASE_URL}/reset-password/confirm`;
export const RESET_PASSWORD_COMPLETE_URL = `${BASE_URL}/reset-password/complete`;
export const USER_URL = `${BASE_URL}/user`;
export const USER_PICTURE_URL = `${BASE_URL}/user/picture`;
export const USER_PWD_URL = `${BASE_URL}/user/password`;

export const USER_EMAIL_URL = `${BASE_URL}/user/email`;
export const USER_EMAIL_CONFIRM_URL = `${BASE_URL}/user/email/confirm`;
export const USER_EMAIL_COMPLETE_URL = `${BASE_URL}/user/email/complete`;

export const TRAFFIC_INFO_QUERY_KEY = 'traffic-info';
export const USER_QUERY_KEY = 'user';
export const TEAM_QUERY_KEY = 'team';
export const GAME_QUERY_KEY = 'games';
export const TICKETING_QUERY_KEY = 'ticketing';

export type ApiResponse<T = unknown> = {
    data: T;
};

export type ApiError = {
    error: {
        code: number;
        message: string;
    };
};

export type RecentlyViewedPayload = {
    type: string;
    media_id: string;
};

export type SignupPayload = {
    firstName: string;
    lastName: string;
    birthday: string;
    email: string;
    password: string;
};

export type FormPayload = {
    gender: string;
    firstName: string;
    lastName: string;
    address: {
        street: string;
        zip: string;
        city: string;
    };
    birthday: string;
    email: string;
    phoneNumber: string;
};

export type TicketBourseFormPayload = {
    name: string;
    phone: string;
    email: string;
    title: string;
    description: string;
    date: string;
    user_id: string;
    type: string;
};

export type LetzAiFormType = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    prompt?: string;
    policyAccepted?: boolean;
    ageConfirmation?: boolean;
};

export type LetzAiFormPromptPayload = {
    email: string;
    name: string;
    phone: string;
    prompt: string;
    promptPlaceholder: string;
    campaignSlug: string;
    apiKey: string;
    promptDescription: string;
    imagesToGenerate: string;
};

export type ValidateSignupPayload = {
    token: string;
    email: string;
};

export type ResendValidationPayload = {
    email: string;
};

export type LoginPayload = {
    email: string;
    password: string;
};

export type LoginResponse = {
    token: string;
};

const getUrlSegments = () => {
    return window.location.pathname.split('/').length - 1;
};

export const handleResponse = (response: Response) => {
    if (response?.status > 299) {
        throw new Error(response?.statusText || 'error');
    }
};

const formatResponse = async <T = unknown>(response: Response): Promise<T> => {
    const { data } = await response.json();
    return data;
};

const handleAndFormatResponse = async (response: Response) => {
    const { data } = await response.json();
    if (response?.status > 299) {
        throw data.error;
    }
    return data;
};

const getHeaders = async (authorization = false) => {
    const headers: HeadersInit = { 'Content-Type': 'application/json' };

    if (authorization) {
        const token = await Preferences.get({ key: TOKEN_IDENTIFIER });
        if (token.value && isValidJwt(token.value) && !isTokenExpired(decodeJWT(token.value))) {
            headers.Authorization = `Bearer ${token.value}`;
        }
    }

    return headers;
};

export const signup = async <T = Pick<User, 'id' | 'email'>>(
    payload: SignupPayload,
): Promise<ApiResponse<ApiResponse<T>>> => {
    const response = await fetch(REGISTER_URL, { body: JSON.stringify(payload), method: 'POST' });
    return handleAndFormatResponse(response);
};

export const participate = async (id: number, payload: FormPayload): Promise<Response> => {
    const response = await fetch(PARTICIPATE_IN_GAME_URL(id), { body: JSON.stringify(payload), method: 'POST' });
    handleResponse(response);
    return response;
};

export const sendTicketBourse = async (payload: TicketBourseFormPayload): Promise<Response> => {
    const response = await fetch(TICKETINGPAGE_SEND_TICKET_URL, { body: JSON.stringify(payload), method: 'POST' });
    handleResponse(response);
    return response;
};

export const sendLetzAiPrompt = async (payload: LetzAiFormPromptPayload): Promise<string[]> => {
    const response = await fetch(LETZAI_GENERATE_IMAGE, { body: JSON.stringify(payload), method: 'POST' });
    return handleAndFormatResponse(response);
};

export const getLetzAiPicture = async (payload: { imagesId: string[]; apiKey: string }): Promise<Response> => {
    const headers = await getHeaders();
    const response = await fetch(`${LETZAI_URL}/images`, { body: JSON.stringify(payload), method: 'POST', headers });
    return response;
};

export const submitLetzAiPicture = async (pictureId: string): Promise<Response> => {
    const response = await fetch(`${LETZAI_URL}/images/${pictureId}/save`);
    handleResponse(response);
    return response;
};

export const getLetzAiGallery = async (slug: string): Promise<Picture[]> => {
    const response = await fetch(`${LETZAI_URL}/${slug}/images`);
    return (await handleAndFormatResponse(response)).gallery;
};

export const getApprovedLetzAiPicture = async (id: string): Promise<LetzAiApprovedPicture> => {
    const response = await fetch(`${LETZAI_URL}/images/${id}`);
    return handleAndFormatResponse(response);
};

export const login = async <T = LoginResponse>(payload: LoginPayload): Promise<T> => {
    const headers = await getHeaders();
    const response = await fetch(LOGIN_URL, { body: JSON.stringify(payload), method: 'POST', headers });
    handleResponse(response);

    const { data } = await response.json();
    return data;
};

export const addRecentlyViewed = async <T = Response>(payload: RecentlyViewedPayload): Promise<T> => {
    const headers = await getHeaders(true);
    const response = await fetch(PODCAST_REPLAY_RECENTLY_VIEWED, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    const { data } = await response.json();
    return data;
};

export const resendValidation = async <T = { message: string }>(email: string): Promise<ApiResponse<T>> => {
    const response = await fetch(SEND_VALIDATION_URL, { body: JSON.stringify({ email }), method: 'POST' });
    return handleAndFormatResponse(response);
};

export const validateSignup = async <T = { message: string }>(
    payload: ValidateSignupPayload,
): Promise<ApiResponse<T>> => {
    const response = await fetch(VALIDATE_REGISTER_URL, { body: JSON.stringify(payload), method: 'POST' });
    return handleAndFormatResponse(response);
};

export const resetPassword = async (email: string) => {
    const response = await fetch(RESET_PASSWORD_URL, { body: JSON.stringify({ email }), method: 'POST' });
    return handleAndFormatResponse(response);
};

export const resetPasswordConfirmation = async (email: string, otp: string) => {
    const response = await fetch(RESET_PASSWORD_CONFIRMATION_URL, {
        body: JSON.stringify({ email, otp }),
        method: 'POST',
    });
    return handleAndFormatResponse(response);
};

export const resetPasswordComplete = async (email: string, password: string, token: string) => {
    const response = await fetch(RESET_PASSWORD_COMPLETE_URL, {
        body: JSON.stringify({ email, password, token }),
        method: 'POST',
    });
    return formatResponse(response);
};

export const getPicturesReports = async (limit = 8, start = 0): Promise<PictureReportResponse> => {
    const headers = await getHeaders();
    const adPayload = await generateAdPayload('pictures-reports-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${BASE_URL}/pictures-reports?limit=${limit}&start=${start}`, {
        body: JSON.stringify(adPayload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    return formatResponse<PictureReportResponse>(response);
};

export const getPicturesReport = async (reportId: string, limit = 8, start = 0): Promise<PictureDetailResponse> => {
    const headers = await getHeaders();
    const responseImages = await fetch(`${BASE_URL}/pictures-reports/${reportId}`);
    handleResponse(responseImages);
    return formatResponse<PictureDetailResponse>(responseImages);
};

export const getWebChannels = async <T = WebChannel[]>(): Promise<T> => {
    const response = await fetch(`${WEBCHANNELS_URL}`);
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getCharts = async <T = Charts[]>(chart: string): Promise<T> => {
    const response = await fetch(`${CHARTS_URL}/${chart}`);
    handleResponse(response);
    return formatResponse<T>(response);
};

export const sendVote = async <T = any>(chart: string, position: number): Promise<Response> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${CHARTS_URL}/${chart}/vote`, {
        body: JSON.stringify({ position }),
        method: 'POST',
        headers,
    });
    return response;
};

export const getHomePage = async <T = HomePageData>(): Promise<T> => {
    const headers = await getHeaders();
    const adPayload = await generateAdPayload('homepage-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${HOMEPAGE_URL}`, { body: JSON.stringify(adPayload), method: 'POST', headers });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getPodcastAndReplayPage = async <T = PodcastAndReplayPageData>(): Promise<T> => {
    const headers = await getHeaders();
    const adPayload = await generateAdPayload('podcast-replay-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${PODCAST_OR_REPLAY_URL}`, {
        body: JSON.stringify(adPayload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getPodcastReplay = async <T = PodcastOrReplay>(id: string, type: string): Promise<T> => {
    const response = await fetch(`${BASE_URL}/${type}s/${id}`);
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getReplaysByCategory = async <T = PodcastOrReplay[] | CategorizedReplays>(
    category: string,
    pageName?: string,
): Promise<T> => {
    const headers = await getHeaders();
    const adPayload = await generateAdPayload(
        pageName || 'podcast-replay-native',
        getUrlSegments(),
        window.location.pathname,
    );
    const response =
        category === 'podcast'
            ? await fetch(`${PODCASTS_URL}`, { body: JSON.stringify(adPayload), method: 'POST', headers })
            : await fetch(`${REPLAYS_URL}/categories/${category}`, {
                  body: JSON.stringify(adPayload),
                  method: 'POST',
                  headers,
              });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getNews = async <T = NewsResponse>(limit = 8, start = 0): Promise<T> => {
    const headers = await getHeaders();
    const adPayload = await generateAdPayload('news-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${NEWS_URL}?limit=${limit}&start=${start}`, {
        body: JSON.stringify(adPayload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getAds = async <T = AdResponse>(payload: AdPayload): Promise<T> => {
    const headers = await getHeaders();
    const responseAds = await fetch(`${BASE_URL}/ads${payload.source.page === 'track-pixel' ? '/track' : ''}`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers,
    });
    handleResponse(responseAds);
    return responseAds.json();
};

export const getAd = async (url: string): Promise<any> => {
    const response = await fetch(url);
    handleResponse(response);
    return response;
};

export const getNewsByCategory = async <T = NewsResponse>(
    idCategory: NewsCategory['id'],
    limit = 8,
    start = 0,
): Promise<T> => {
    const headers = await getHeaders();
    const adPayload = await generateAdPayload('news-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${NEWS_BY_CATEGORY_URL}/${idCategory}?limit=${limit}&start=${start}`, {
        body: JSON.stringify(adPayload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getNewsCategories = async <T = NewsCategory[]>(): Promise<T> => {
    const response = await fetch(`${NEWS_CATEGORIES_URL}`);
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getNewsByTag = async <T = NewsResponse>(
    tag: string,
    limit = 8,
    start = 0,
    pageName?: string,
): Promise<T> => {
    const headers = await getHeaders();
    const adPayload = await generateAdPayload(pageName || 'news-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${ARTICLE_TAG_URL}/${tag}?limit=${limit}&start=${start}`, {
        body: JSON.stringify(adPayload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getGamingTags = async <T = GamingTags[]>(): Promise<T> => {
    const response = await fetch(GAMING_TAGS_URL);
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getRadio = async (): Promise<Radio> => {
    const response = await fetch(`${RADIO_URL}`);
    handleResponse(response);
    return formatResponse<Radio>(response);
};

export const getTopicCategories = async (): Promise<TopicCategory[]> => {
    const response = await fetch(`${TOPIC_CATEGORIES_URL}`);
    handleResponse(response);
    return formatResponse<TopicCategory[]>(response);
};

export const getTopicCategory = async (topicCategId: string): Promise<TopicCategory> => {
    const response = await fetch(`${TOPIC_CATEGORIES_URL}/${topicCategId}`);
    handleResponse(response);
    return formatResponse<TopicCategory>(response);
};

export const getArticlesByTopicCategory = async (topicCategId: string): Promise<Article[]> => {
    const response = await fetch(`${TOPICS_URL(topicCategId)}`);
    handleResponse(response);
    return formatResponse<Article[]>(response);
};

export const getArticle = async (articleId: string): Promise<Article | undefined> => {
    const response = await fetch(`${TOPIC_ARTICLE_URL(articleId)}`);
    handleResponse(response);
    const responseData = formatResponse<Article>(response);
    return Array.isArray(await responseData) ? undefined : responseData;
};

export type GetEventsParams = {
    name?: string | null;
    date?: string | null;
    endDate?: string | null;
    category?: { id: string } | null;
    page?: number;
};

export const getEvents = async (filters?: GetEventsParams, pageName?: string): Promise<EventResponse> => {
    function getParams() {
        if (!filters) return '';
        const params: string[] = [];
        if (filters?.name?.length) params.push(`keyword=${filters.name}`);
        if (filters?.date?.length) params.push(`start_date=${moment(filters.date).format('YYYY-MM-DD')}`);
        if (filters?.endDate?.length) params.push(`end_date=${moment(filters.endDate).format('YYYY-MM-DD')}`);
        if (filters?.category?.id) params.push(`genre=${filters.category.id}`);
        if (filters?.page) params.push(`page=${filters.page}`);
        return `${params.length ? '?' : ''}${params.join('&')}`;
    }
    const headers = await getHeaders();
    const adPayload = await generateAdPayload(pageName || 'events-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${EVENTS_URL}${getParams()}`, {
        body: JSON.stringify(adPayload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    const { data } = await response.json();
    return {
        events: data.events.map((e: any) => ({
            ...e,
            imageUrl: e.image,
            tags: e.tags || [],
        })),
        count: data.pagination.count,
        perPage: data.pagination.perPage,
        currentPage: data.pagination.currentPage,
    };
};

export const getEventById = async (id: Event['id']): Promise<Event> => {
    const response = await fetch(`${EVENTS_URL}/${id as string}`);
    handleResponse(response);
    const jsonResponse = await response.json();
    return {
        ...jsonResponse.data,
        imageUrl: jsonResponse.data.image,
        tags: jsonResponse.data.tags || [],
    };
};

export const getEventsCategories = async () => {
    const response = await fetch(EVENTS_CATEGORIES_URL);
    handleResponse(response);
    const jsonResponse = await response.json();
    return jsonResponse.data;
};

export const getTeamPageInfo = async (): Promise<TeamPageInfo> => {
    const response = await fetch(`${TEAMPAGE_INFO_URL}`);
    handleResponse(response);
    return formatResponse<TeamPageInfo>(response);
};

export const getGamePageInfo = async (): Promise<GamePageInfo> => {
    const response = await fetch(`${GAMEPAGE_INFO_URL}`);
    handleResponse(response);
    return formatResponse<GamePageInfo>(response);
};

export const getTicketingPageInfo = async (): Promise<TicketingPageInfo> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${TICKETINGPAGE_INFO_URL}`, { headers });
    handleResponse(response);
    return formatResponse<TicketingPageInfo>(response);
};

export const getTrafficInfo = async (id?: string): Promise<TrafficInfo> => {
    const headers = await getHeaders(true);
    const adPayload = await generateAdPayload(
        'traffic-native',
        getUrlSegments(),
        window.location.pathname,
        undefined,
        id,
    );
    const response = await fetch(`${TRAFFIC_INFO_URL}`, { body: JSON.stringify(adPayload), method: 'POST', headers });
    handleResponse(response);
    return formatResponse<TrafficInfo>(response);
};

export const postCameraToFavorites = async (id: string) => {
    const headers = await getHeaders(true);
    const response = await fetch(`${TRAFFIC_INFO_URL}/cameras/favorites`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ id }),
    });

    handleResponse(response);
    return response;
};

export const deleteCameraFromFavorites = async (id: string) => {
    const headers = await getHeaders(true);
    const response = await fetch(`${TRAFFIC_INFO_URL}/cameras/favorites/${id}`, { method: 'DELETE', headers });
    handleResponse(response);
    return response;
};

export const getWeather = async (longitude: number, latitude: number): Promise<Weather> => {
    const headers = await getHeaders();
    const response = await fetch(`${WEATHER_URL}`, {
        method: 'POST',
        body: JSON.stringify({ latitude, longitude }),
        headers,
    });
    handleResponse(response);
    return formatResponse<Weather>(response);
};

export const getUser = async (): Promise<User> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${USER_URL}`, {
        method: 'GET',
        headers,
    });
    return handleAndFormatResponse(response);
};
export const updateUser = async (data: Partial<User>): Promise<User> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${USER_URL}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
    });
    handleResponse(response);
    return formatResponse<User>(response);
};

export const updateUserPicture = async (picture: File): Promise<void> => {
    const headers = await getHeaders(true);
    delete headers['Content-Type'];
    const data = new FormData();
    data.append('picture', picture, 'picture');
    const response = await fetch(`${USER_PICTURE_URL}`, {
        method: 'POST',
        headers,
        body: data,
    });
    handleResponse(response);
    return formatResponse(response);
};

export const updateUserEmail = {
    init: async (email: string) => {
        const headers = await getHeaders(true);

        const response = await fetch(`${USER_EMAIL_URL}`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ email }),
        });
        handleResponse(response);
        return formatResponse(response);
    },
    confirm: async ({ email, otp }: { email: string; otp: string }) => {
        const headers = await getHeaders(true);

        const response = await fetch(`${USER_EMAIL_CONFIRM_URL}`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ email, otp }),
        });
        handleResponse(response);
        return formatResponse(response);
    },
    complete: async ({ email, newEmail, token }: { email: string; newEmail: string; token: string }) => {
        const headers = await getHeaders(true);

        const response = await fetch(`${USER_EMAIL_COMPLETE_URL}`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ email, newEmail, token }),
        });
        handleResponse(response);
        return formatResponse(response);
    },
};

type UpdateUserPasswordProps = { password: string; validateNewPassword: string; newPassword: string };
export const updateUserPassword = async (data: UpdateUserPasswordProps) => {
    const headers = await getHeaders(true);
    const response = await fetch(`${USER_PWD_URL}`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data),
    });
    handleResponse(response);
    return formatResponse<User>(response);
};

export const getShowsPage = async (date: string): Promise<ShowsPageInfo> => {
    const params = new URLSearchParams({ date });
    const headers = await getHeaders(true);
    const response = await fetch(`${SHOWS_URL}?${params.toString()}`, { method: 'GET', headers });
    handleResponse(response);
    return formatResponse<ShowsPageInfo>(response);
};

export const getAllShows = async (): Promise<Show[]> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${SHOWS_URL}`, { method: 'GET', headers });
    handleResponse(response);
    return formatResponse<Show[]>(response);
};

export const getCinemas = async (): Promise<CinemasResponse> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${CINEMAS_URL}`, { method: 'GET', headers });
    handleResponse(response);
    return formatResponse<CinemasResponse>(response);
};

export const getMoviesByCinema = async (
    cinemaId: string | number,
    startDate: string,
    endDate: string,
    title?: string,
): Promise<MoviesByCinemaResponse> => {
    const headers = await getHeaders(true);
    const response = await fetch(
        `${CINEMAS_URL}/movies/search?cinemaId=${cinemaId}&startDate=${startDate}&endDate=${endDate}&title=${
            title ?? ''
        }`,
        { method: 'GET', headers },
    );
    handleResponse(response);
    return formatResponse<MoviesByCinemaResponse>(response);
};

export const getMovie = async (id: string | number): Promise<MovieResponse> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${CINEMAS_URL}/movies/${id}`, { method: 'GET', headers });
    handleResponse(response);
    return formatResponse<MovieResponse>(response);
};

export const getCinemaArticles = async <T = CinemaArticles>(
    filmsOfTheWeek = false,
    limit = 8,
    start = 0,
): Promise<T> => {
    const headers = await getHeaders(true);
    const adPayload = await generateAdPayload('cinema-articles-native', getUrlSegments(), window.location.pathname);
    const response = filmsOfTheWeek
        ? await fetch(`${BASE_URL}/cinemas/movies/week?limit=${limit}&start=${start}`, {
              body: JSON.stringify(adPayload),
              method: 'POST',
              headers,
          })
        : await fetch(`${BASE_URL}/cinemas/news?limit=${limit}&start=${start}`, {
              body: JSON.stringify(adPayload),
              method: 'POST',
              headers,
          });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getCinemaBoxOfficeArticles = async <T = CinemaArticles>(limit = 8, start = 0): Promise<T> => {
    const headers = await getHeaders(true);
    const adPayload = await generateAdPayload('box-office-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${BASE_URL}/cinemas/movies/boxoffice?limit=${limit}&start=${start}`, {
        body: JSON.stringify(adPayload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getCinemaDateRanges = async (): Promise<WeekDates[]> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${BASE_URL}/cinemas/weekdates`, { method: 'GET', headers });
    handleResponse(response);
    return formatResponse<WeekDates[]>(response);
};

export const getTiktokUrl = async (deviceType: string): Promise<string> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${BASE_URL}/tiktok/authUrl/${deviceType}`, { method: 'GET', headers });
    handleResponse(response);
    return formatResponse<string>(response);
};

export const getFacebookUrl = async (deviceType: string): Promise<string> => {
    const headers = await getHeaders(true);
    const response = await fetch(`${BASE_URL}/facebook/authUrl/${deviceType}`, { method: 'GET', headers });
    handleResponse(response);
    return formatResponse<string>(response);
};

export const getActions = async <T = ActionList[]>(current: boolean): Promise<T> => {
    const headers = await getHeaders();
    const adPayload = await generateAdPayload('actions-native', getUrlSegments(), window.location.pathname);
    const response = current
        ? await fetch(`${BASE_URL}/aktiounen/current`, { body: JSON.stringify(adPayload), method: 'POST', headers })
        : await fetch(`${BASE_URL}/aktiounen/archived`, { body: JSON.stringify(adPayload), method: 'POST', headers });
    handleResponse(response);
    return formatResponse<T>(response);
};

export const getActionArticle = async (slug: string): Promise<ActionList> => {
    const headers = await getHeaders(true);
    const adPayload = await generateAdPayload('action-detail-native', getUrlSegments(), window.location.pathname);
    const response = await fetch(`${BASE_URL}/aktiounen/aktioun/${slug}`, {
        body: JSON.stringify(adPayload),
        method: 'POST',
        headers,
    });
    handleResponse(response);
    return formatResponse<ActionList>(response);
};

export const addView = async (pagePath: string) => {
    const viewPayload = {
        device: await getDeviceType(),
        page: pagePath,
        source: isPlatform('cordova') ? 'app' : 'website',
    };
    const response = await fetch(`${BASE_URL}/views`, { body: JSON.stringify(viewPayload), method: 'POST' });

    return formatResponse<any>(response);
};
