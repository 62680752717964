import { useTranslation } from 'react-i18next';
import Img from './Img';
import Section from './core/Section';

export interface HomePageHeaderProps {
    className?: string;
    src?: string;
    title?: string;
    subtitle?: string;
}

export default function HomePageHeader({ className, src, title, subtitle }: HomePageHeaderProps) {
    const { t } = useTranslation();

    return (
        <Section className={`items-center flex md:flex-row flex-col -mt-8 justify-center ${className || ''}`}>
            <>
                <Img
                    src={`${src || '/assets/img/Welcome_Chiko.png'}`}
                    className="md:max-w-[250px] max-w-[200px] md:mb-0 mb-10"
                    alt="Header Eldoradio"
                />
                <div className="grid gap-[12px] ml-[25px]">
                    <span className="font-bold md:text-[48px] text-[32px] md:leading-[64px] leading-[48px]">
                        {title || t('welcome_to_eldo')}
                    </span>
                    <span className="md:text-[36px] text-[24px] md:leading-[64px] leading-[24px] md:mb-0 mb-8">
                        {subtitle || title ? subtitle : t('welcome_to_eldo_subtitle')}
                    </span>
                </div>
            </>
        </Section>
    );
}
