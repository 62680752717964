import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { isPlatform } from '@ionic/core';

const categoryMapping: Record<string, string> = {
    '/aktuell/news': 'News',
    '/events/agenda': 'Events',
    '/radio/team': 'Team',
    '/aktuell/radio': 'Radio',
    '/traffic-info': 'Traffic',
    '/podcast-replay/type/eldo4real/': 'Eldo4real',
    '/podcast-replay/category/eldo4real': 'Eldo4real',
    '/podcast-replay': 'Podcast & Replay',
    '/aktiounen': 'Actions',
    '/radio/shows': 'Shows',
    '/game': 'Giveaways',
    '/aktuell/meteo': 'Meteo',
    '/kino': 'Kino',
    '/radio/gaming': 'Gaming',
    '/radio/highscore': 'Highscore',
    '/radio/travel': 'Travel',
    '/radio/fashionAndBeauty': 'Fashion & Beauty',
    '/radio/lifestyle': 'Lifestyle',
    '/radio/foodAndDrinks': 'Food & Drinks',
    '/radio/rubriken': 'Topics',
    '/events/fotoen': 'Picture reports',
    '/musek/charts': 'Chartbreaker',
    '/events/ticket-bourse': 'Ticket bourse',
    '/aktuell/shop': 'Shop'

};

function AnalyticsCategory() {
    const location = useLocation();

    useEffect(() => {
        if (!isPlatform('cordova')) {
            let category;

        if (location.pathname === '/' || location.pathname === '/home') {
            category = 'Home';
        } else {
            category =
                categoryMapping[location.pathname] ||
                Object.keys(categoryMapping).find((key) =>
                    location.pathname.startsWith(key)
                ) ||
                'Other';
        }

        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: document.title,
            section: categoryMapping[category] || category,
        });
        }
        
    }, [location]);

    return null;
}

export default AnalyticsCategory;
