import { useEffect, useRef, useState } from 'react';
import {
    LetzAiFormType,
    LetzAiFormPromptPayload,
    sendLetzAiPrompt,
    getLetzAiPicture,
    submitLetzAiPicture,
} from 'lib/ApiService';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AdditionalData } from 'types';
import LetzAiForm from './LetzAiForm';
import LetzAiPrompt from './LetzAiPrompt';
import { TOAST_DURATION } from '../../constants';
import LetzAiPicture from './LetzAiPicture';
import LetzAiParticiapted from './LetzAiParticiapted';
import LetzAiError from './LetzAiError';

const LETZ_AI_ERROR = 0;
const LETZ_AI_FORM = 1;
const LETZ_AI_PROMPT = 2;
const LETZ_AI_PICTURE = 3;
const LETZ_AI_PARTICIPATED = 4;

type LetzAiProps = {
    className?: string;
    additionalData?: AdditionalData & { slug: string };
};

export default function LetzAi({ className, additionalData }: LetzAiProps) {
    const [present] = useIonToast();
    const { t } = useTranslation();
    const [pictures, setPictures] = useState<string[]>([]);
    const [step, setStep] = useState(LETZ_AI_FORM);
    const [data, setData] = useState<LetzAiFormPromptPayload>({
        email: '',
        name: '',
        phone: '',
        prompt: '',
        promptPlaceholder: additionalData?.promptPlaceholder ?? '',
        campaignSlug: additionalData?.slug ?? '',
        apiKey: additionalData?.apiKey ?? '',
        promptDescription: additionalData?.prompt ?? '',
        imagesToGenerate: additionalData?.image_count ?? '3',
    });
    const [isLoadingPicture, setIsLoadingPicture] = useState<boolean>(true);

    const intervalId = useRef<number | null>(null);
    const timeoutId = useRef<number | null>(null);

    const reset = () => {
        setStep(LETZ_AI_FORM);
    };

    const somethingWentWrong = () => {
        reset();
        setIsLoadingPicture(false);
        present({ color: 'danger', duration: TOAST_DURATION, message: t('letzAi.letzAiError') });
        setStep(LETZ_AI_ERROR);
    };

    useEffect(() => {
        return () => {
            if (intervalId.current !== null) clearInterval(intervalId.current);
            if (timeoutId.current !== null) clearTimeout(timeoutId.current);
        };
    }, []);

    function callGetTotoUntilSuccess(pictureId: string[]) {
        return new Promise<void>((resolve, reject) => {
            const interval = 5000;
            const maxTimeout = 120000;
            const initialDelay = 20000;
    
            const startInterval = () => {
                intervalId.current = window.setInterval(async () => {
                    try {
                        const response = await getLetzAiPicture({
                            imagesId: pictureId,
                            apiKey: data.apiKey,
                        });
    
                        if (response.status === 200) {
                            const responseAiPrompt = await response.json();
                            if (responseAiPrompt.data.length === Number(data.imagesToGenerate)) {
                                clearInterval(intervalId.current || 0);
                                clearTimeout(timeoutId.current || 0);
                                setIsLoadingPicture(false);
                                setPictures(responseAiPrompt.data);
                                resolve();
                            }
                        } else if (response.status >= 400 && response.status !== 400) {
                            clearInterval(intervalId.current || 0);
                            clearTimeout(timeoutId.current || 0);
                            somethingWentWrong();
                            reject(new Error('API responded with error'));
                        }
                    } catch (error: any) {
                        if (error.status !== 400) {
                            clearInterval(intervalId.current || 0);
                            clearTimeout(timeoutId.current || 0);
                            somethingWentWrong();
                            reject(error);
                        }
                    }
                }, interval);
            };
    
            // Start the interval after an initial delay
            setTimeout(() => {
                startInterval();
            }, initialDelay);
    
            // Set a timeout to reject the promise if it exceeds 2 minutes
            timeoutId.current = window.setTimeout(() => {
                if (intervalId) clearInterval(intervalId.current || 0);
                reject(new Error('Operation timed out after 2 minutes'));
            }, maxTimeout);
        });
    }

    const onStep1Completed = (formData: LetzAiFormType) => {
        setStep(LETZ_AI_PROMPT);
        const { email, phone, prompt } = formData;
        setData({
            ...data,
            email,
            name: `${formData.firstName} ${formData.lastName}`,
            prompt: prompt ?? '',
            phone,
            campaignSlug: data.campaignSlug,
            apiKey: data.apiKey,
        });
    };

    const onStep2Completed = (prompt: string) => {
        setData((prev) => ({ ...prev, prompt }));
        setIsLoadingPicture(true);
        setStep(LETZ_AI_PICTURE);
        sendLetzAiPrompt({ ...data, prompt })
            .then((imageResult) => {
                callGetTotoUntilSuccess(imageResult);
            })
            .catch(somethingWentWrong);
    };

    const onStep3Completed = (pictureId: string) => {
        submitLetzAiPicture(pictureId)
            .then(() => {
                present({
                    color: 'success',
                    duration: TOAST_DURATION,
                    message: t('letzAi.imageSubmitted'),
                });
                setStep(LETZ_AI_PARTICIPATED);
            })
            .catch(somethingWentWrong);
    };

    const onStep4Completed = () => {
        setStep(LETZ_AI_PROMPT);
    };

    return (
        <div className={clsx(className, 'mx-auto w-full')}>
            {step === LETZ_AI_ERROR && <LetzAiError onComplete={reset} />}
            {step === LETZ_AI_FORM && <LetzAiForm onComplete={onStep1Completed} />}
            {step === LETZ_AI_PROMPT && (
                <LetzAiPrompt
                    onComplete={onStep2Completed}
                    description={data.promptDescription}
                    promptPlaceholder={data.promptPlaceholder}

                />
            )}
            {step === LETZ_AI_PICTURE && (
                <LetzAiPicture
                    onComplete={onStep3Completed}
                    isLoadingPicture={isLoadingPicture}
                    pictures={pictures}
                    promptAgain={() => setStep(LETZ_AI_PROMPT)}
                />
            )}
            {step === LETZ_AI_PARTICIPATED && <LetzAiParticiapted onComplete={onStep4Completed} />}
        </div>
    );
}
