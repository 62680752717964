import { Suspense, useEffect } from 'react';
import { isPlatform, setupIonicReact } from '@ionic/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'i18n';
import '@ionic/react/css/core.css';

import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './theme/theme.scss';
import 'swiper/css';

import { AppTrackingTransparency, AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import useOneSignal from 'hooks/useOneSignal';
import { DidomiSDK } from '@didomi/react';
import { Preferences } from '@capacitor/preferences';
import { getPlatformForGemius } from 'features/advertising/generateAdPayload';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setPortraitModeOnPhone } from 'lib/utils';
import { App as CapacitorApp } from '@capacitor/app';
import initGA from 'lib/GoogleAnalyticsService';
import { PluginListenerHandle } from '@capacitor/core';
import AppRoutes from './AppRoutes';
import PlayerProvider from './features/radio/PlayerContext';
import RadioProvider from './features/radio/RadioContext';
import ChannelProvider from './features/radio/ChannelContext';
import AuthProvider from './features/auth/AuthContext';

setupIonicReact();

if (isPlatform('hybrid')) {
    StatusBar.setStyle({ style: Style.Dark });
}

const queryClient: QueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 5000,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});

function saveConsent(consent: boolean) {
    localStorage.setItem(
        'consentStatus',
        JSON.stringify({
            consent,
        }),
    );
}

export default function App() {
    // initialize modules
    // //////////////////////////////
    useOneSignal();
    const metriwebIdentifier = process.env.REACT_APP_METRIWEB_IDENTIFIER || '';
    getPlatformForGemius().then((device) => {
        // @ts-ignore
        metriweb(metriwebIdentifier);
    });
    // //////////////////////////////

    useEffect(() => {
        const script = document.createElement('script');
        let listener: PluginListenerHandle | null = null;

        (async () => {
            listener = await CapacitorApp.addListener('appStateChange', ({ isActive }) => {
                if (isActive) {
                    getPlatformForGemius().then((device) => {
                        // @ts-ignore
                        metriweb(metriwebIdentifier);
                    });
                }
            });
        })();
        return () => {
            if (listener) {
                listener.remove(); // Ensure listener is removed
            }
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        initGA();
        try {
            if (isPlatform('ios')) {
                AppTrackingTransparency.getStatus()
                    .then(async (trackStat: AppTrackingStatusResponse) => {
                        if (trackStat.status !== 'authorized') {
                            await AppTrackingTransparency.requestPermission();
                            localStorage.setItem('appTrackingTransparency', '1');
                        } else {
                            localStorage.setItem('appTrackingTransparency', '0');
                        }
                    })
                    .catch((e) => console.warn('IOS Error', e));
            }
            setPortraitModeOnPhone();
        } catch (e) {
            console.warn('Authorization failed', e);
        }
        setTimeout(() => {
            SplashScreen.hide();
        }, 1000);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Suspense fallback="loading...">
                <AuthProvider>
                    <RadioProvider>
                        <ChannelProvider>
                            <PlayerProvider>
                                <DidomiSDK
                                    sdkPath="https://didomi.eldo.lu/"
                                    apiKey={process.env.REACT_APP_DIDOMI_APIKEY ?? ''}
                                    noticeId={process.env.REACT_APP_DIDOMI_NOTICEID ?? 'VAe3eCCU'}
                                    iabVersion={2}
                                    gdprAppliesGlobally
                                    onReady={(didomi) =>
                                        saveConsent(didomi?.getUserConsentStatus('create_ads_profile', 1) ?? false)
                                    }
                                    onConsentChanged={(consents) =>
                                        saveConsent(
                                            consents?.consents?.find(
                                                (consent: { purpose: string }) =>
                                                    consent.purpose === 'create_ads_profile',
                                            )?.vendors[0]?.status,
                                        )
                                    }
                                    onNoticeHidden={() => window.location.reload()}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <AppRoutes />
                                </LocalizationProvider>
                            </PlayerProvider>
                        </ChannelProvider>
                    </RadioProvider>
                </AuthProvider>
            </Suspense>
        </QueryClientProvider>
    );
}
