import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { useLocation } from 'react-router-dom';
import { Device } from '@capacitor/device';

export default function useFirebaseAnalytics() {
    const location = useLocation();

    async function handleLocationChange() {
        if ((await Device.getInfo()).platform !== 'web') {
            FirebaseAnalytics.logEvent({
                name: 'screen_view',
                params: {
                    screen_name: window.location.pathname,
                    screen_class: window.location.pathname,
                },
            });
        }
    }
    useEffect(() => {
        handleLocationChange();
    }, [location]);
}
